import React from 'react';
import '../css/AnimatedBackground.css';

const AnimatedBackground = () => (
  <div className="area">
    <ul className="circles">
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
);
export default AnimatedBackground;
