import React from 'react';
import '../css/CopyWritingServices.css';
import AnimatedBackground
from './AnimatedBackground';
const CopywritingServices = () => (
  
  <div id="copywriting" className="copywriting-services-container">
    <AnimatedBackground />
    <h2 className="copywriting-services-heading">Copywriting Services</h2>
    <p className="copywriting-services-paragraph">
      Copywriting is, for those who may not know, the art of crafting persuasive and compelling written content for various mediums, such as advertisements, websites, marketing materials, and more. If you’re in need of something written from scratch!
    </p>
  </div>
);


export default CopywritingServices;
